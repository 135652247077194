import Requester from "../requester";

const API_BASE_URL = process.env.REACT_APP_ONBOARDING_API_BASE_URL;

export default class Onboarding {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  signUp(data) {
    return this.apiRequester.post({
      endpoint: "api/v2/finch-onboarding/signup",
      body: data,
    });
  }

  getApplicationById(applicationId) {
    return this.apiRequester.get({
      endpoint: `api/v2/finch-onboarding/applications/${applicationId}`,
    });
  }

  getApplicationByEmailAndPhone(emailOrPhone) {
    return this.apiRequester.get({
      endpoint: "api/v2/finch-onboarding/application",
      params: {
        identifier: emailOrPhone,
      },
    });
  }

  saveAsDraft(application) {
    return this.apiRequester.put({
      endpoint: "api/v2/finch-onboarding/draft",
      body: application,
    });
  }

  submitAgentApplicationOld(application) {
    return this.apiRequester.put({
      endpoint: "api/v2/finch-onboarding/application",
      body: application,
    });
  }

  documentUpload(applicationId, documentType, file) {
    const formData = new FormData();
    formData.append("file", file);

    return this.apiRequester.post({
      endpoint: "api/v2/finch-onboarding/document",
      headers: {
        applicationId: applicationId,
        documentType: documentType,
        "content-type": "multipart/form-data",
        accept: "*/*",
      },
      body: formData,
    });
  }

  validateApplication(agentClassId, application, superAgent) {
    if (agentClassId == 6) {
      return this.apiRequester.put({
        endpoint: `api/v3/finch-onboarding/applications/${application.applicationId}/status/validate`,
      });
    } else {
      return this.apiRequester.put({
        endpoint: `api/v2/finch-onboarding/application/validate?agentClassId=${agentClassId}&applicationId=${application.applicationId}`,
      });
    }
  }

  approveApplication(agentClassId, application, superAgent) {
    if (agentClassId == 6) {
      return this.apiRequester.put({
        endpoint: `api/v3/finch-onboarding/applications/${application.applicationId}/status/approve`,
      });
    } else {
      return this.apiRequester.put({
        endpoint: `api/v2/finch-onboarding/application/approve?agentClassId=${agentClassId}&applicationId=${application.applicationId}`,
      });
    }
  }

  declineApplication(reason, application, reasonCategory) {
    if (application.agentClassId == 6) {
      return this.apiRequester.put({
        endpoint: `api/v3/finch-onboarding/applications/${application.applicationId}/status/reject`,
        body: {
          reason,
          reasonCategory,
        },
      });
    } else {
      return this.apiRequester.put({
        endpoint: `api/v2/finch-onboarding/application/reject?applicationId=${application.applicationId}&declineReason=${reason}&declineReasonCategory=${reasonCategory}`,
        // body: {
        //   reason,
        //   reasonCategory,
        // },
      });
    }
  }

  saveApplication(application, draft = null) {
    return this.apiRequester.post({
      endpoint: "api/v2/finch-onboarding/applications",
      args: {
        ...(draft != null ? { draft } : {}),
      },
      body: application,
    });
  }

  submitApplication(application, draft = null) {
    return this.apiRequester.put({
      endpoint: "api/v2/finch-onboarding/applications",
      args: {
        ...(draft != null ? { draft } : {}),
      },
      body: application,
    });
  }

  submitSuperAgentApplication(application) {
    return this.apiRequester.post({
      endpoint: "api/v2/finch-onboarding/super-agent/application",
      body: application,
    });
  }

  saveSuperAgentApplicationAsDraft(application) {
    return this.apiRequester.post({
      endpoint: "api/v2/finch-onboarding/super-agent/draft",
      body: application,
    });
  }

  getApplications(status = null) {
    return this.apiRequester.get({
      endpoint: `api/v2/finch-onboarding/applications`,
      args: {
        status,
      },
    });
  }

  updateApplicationStatus(applicationId, applicationStatus) {
    return this.apiRequester.put({
      endpoint: `api/v2/finch-onboarding/application/${applicationStatus}`,
      args: {
        applicationId,
      },
    });
  }

  searchApplications(pageNum = null, pageSize = null, payload) {
    let {
      approvalStatus,
      applicationType,
      phoneNumber,
      businessName,
      applicantName,
      applicationId,
      toDate,
      fromDate,
      emailAddress,
      agentTypeId,
    } = payload;
    if (approvalStatus == "Draft") {
      applicationType = 0;
      approvalStatus = null;
    } else if (approvalStatus == "Cancelled") {
      applicationType = 2;
      approvalStatus = null;
    }

    console.log(approvalStatus, "approvalStatus");
    return this.apiRequester.get({
      endpoint: `api/v2/finch-onboarding/applications/search`,
      args: {
        //approvalStatus: payload.approvalStatus
        pageNum,
        pageSize,
        ...(approvalStatus != "All" ? { approvalStatus } : {}),
        ...(applicationType != null ? { applicationType } : {}),
        ...(phoneNumber != null ? { phoneNumber } : {}),
        ...(businessName != null ? { businessName } : {}),
        ...(applicantName != null ? { applicantName } : {}),
        ...(applicationId != null ? { applicationId } : {}),
        ...(emailAddress != null ? { emailAddress } : {}),
        ...(fromDate != null ? { fromDate } : {}),
        ...(toDate != null ? { toDate } : {}),
        ...(agentTypeId != "All" ? { agentTypeId } : {}),
      },
    });
  }

  addDeclineReasonCategory(payload) {
    return this.apiRequester.post({
      endpoint: `api/v2/finch-onboarding/reasonCategory`,
      args: {
        categoryName: payload.categoryName,
      },
    });
  }

  getDeclineReasonCategories() {
    return this.apiRequester.get({
      endpoint: `api/v2/finch-onboarding/reasonCategories`,
    });
  }

  getDeclineReasonCategoriesByStatus(status) {
    return this.apiRequester.get({
      endpoint: `api/v2/finch-onboarding/reasonCategories/status/true`,
    });
  }

  getDeclineReasonCategory(id) {
    return this.apiRequester.get({
      endpoint: `api/v2/finch-onboarding/reasonCategories/${id}`,
    });
  }

  updateDeclineReasonCategory(payload) {
    return this.apiRequester.put({
      endpoint: `api/v2/finch-onboarding/reasonCategory`,
      body: payload,
    });
  }

  deleteDeclineReasonCategory(id) {
    return this.apiRequester.delete({
      endpoint: `api/v2/finch-onboarding/reasonCategories/${id}`,
    });
  }
}
