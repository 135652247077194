import React from "react";
import { formatBackEndDate } from "../../utils/formatter";
import {
  getApplicationType,
  isPhoneNumberValid,
  stripNumbersFromString,
} from "../../utils/helper";
import { checkEmailIsValid } from "../../utils/validator";
import { DatePickerComponent } from "../date-picker";
import PhoneNumberComponent from "../phone-number-component";
import Select from "../select";

export default class ApplicantDetailsForm extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div class="kt-portlet">
          {!this.props.hideTitleHeader && (
            <div
              class="kt-portlet__head"
              style={{ cursor: "pointer" }}
              data-toggle="collapse"
              data-target="#collapseOne1"
              aria-controls="collapseOne1"
            >
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                  {`${this.props.title ? this.props.title : "Applicant"}`}{" "}
                  Details
                </h3>
              </div>
            </div>
          )}

          <form id="collapseOne1" class="collapse show">
            <div class="kt-portlet__body">
              <div class="form-group row">
                {this.props.applicantDetails &&
                  this.props.applicantDetails.firstName && (
                    <div class="col-lg-4 mb-4">
                      <label>First Name *:</label>
                      <input
                        autoComplete="off"
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                        type="name"
                        class="form-control"
                        defaultValue={
                          this.props.applicantDetails
                            ? this.props.applicantDetails.firstName
                            : ""
                        }
                        placeholder="First name"
                        onChange={(event) =>
                          this.props.onChange(
                            "firstName",
                            stripNumbersFromString(event.target.value)
                          )
                        }
                      />
                    </div>
                  )}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.middleName && (
                    <div class="col-lg-4 mb-4">
                      <label>Middle Name *: </label>
                      <input
                        autoComplete="off"
                        type="name"
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                        class="form-control"
                        defaultValue={
                          this.props.applicantDetails
                            ? this.props.applicantDetails.middleName
                            : ""
                        }
                        placeholder="Middle name"
                        onChange={(event) =>
                          this.props.onChange("middleName", event.target.value)
                        }
                      />
                    </div>
                  )}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.lastName && (
                    <div class="col-lg-4 mb-4">
                      <label>Last Name *:</label>
                      <input
                        type="name"
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                        class="form-control"
                        defaultValue={
                          this.props.applicantDetails
                            ? this.props.applicantDetails.lastName
                            : ""
                        }
                        placeholder="Last name"
                        onChange={(event) =>
                          this.props.onChange("lastName", event.target.value)
                        }
                      />
                    </div>
                  )}
                {/* </div> */}

                {/* <div class="form-group row"> */}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.mothersMaidenName && (
                    <div class="col-lg-4 mb-4">
                      <label>Mother's maiden name *:</label>
                      <div class="input-group">
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails
                              ? this.props.applicantDetails.mothersMaidenName
                              : ""
                          }
                          placeholder=""
                          onChange={(event) =>
                            this.props.onChange(
                              "mothersMaidenName",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.identificationType && (
                    <div class="col-lg-4 mb-4">
                      <label>ID Type *:</label>

                      {this.props.disabled ? (
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails
                              ? this.props.getIdentificationTypeName(
                                  this.props.applicantDetails.identificationType
                                )
                              : this.props.applicantDetails.identificationType
                          }
                          placeholder=""
                          onChange={(event) =>
                            this.props.onChange(
                              "identificationType",
                              event.target.value
                            )
                          }
                        />
                      ) : (
                        <Select
                          options={this.props.identificationTypes.map(
                            (detail) => {
                              return { label: detail.name, value: detail.id };
                            }
                          )}
                          onChange={(event) =>
                            this.props.onChange(
                              "identificationType",
                              event.value
                            )
                          }
                          minHeight={37}
                          disabled={this.props.disabled}
                          defaultValue={
                            this.props.applicantDetailsFormData
                              ? this.props.applicantDetailsFormData
                                  .identificationType
                              : this.props.applicantDetails.identificationType
                          }
                        />
                      )}
                    </div>
                  )}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.identificationNumber && (
                    <div class="col-lg-4 mb-4">
                      <label>ID Number *:</label>
                      <div class="input-group">
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails
                              ? this.props.applicantDetails.identificationNumber
                              : ""
                          }
                          placeholder=""
                          onChange={(event) =>
                            this.props.onChange(
                              "identificationNumber",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                {/* </div> */}
                {/* <div class="form-group row"> */}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.placeOfBirth && (
                    <div class="col-lg-4 mb-4">
                      <label>Place of Birth *:</label>
                      <div class="input-group">
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails
                              ? this.props.applicantDetails.placeOfBirth
                              : ""
                          }
                          placeholder=""
                          onChange={(event) =>
                            this.props.onChange(
                              "placeOfBirth",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.dob && (
                    <div class="col-lg-4 mb-4">
                      <label>Date of Birth *:</label>
                      <div class="input-group">
                        <DatePickerComponent
                          selected={
                            this.props.applicantDetailsFormData &&
                            this.props.applicantDetailsFormData.dob
                              ? new Date(
                                  this.props.applicantDetailsFormData.dob
                                )
                              : this.props.applicantDetails &&
                                this.props.applicantDetails.dob
                              ? new Date(this.props.applicantDetails.dob)
                              : ""
                          }
                          onChange={(event) =>
                            this.props.onChange("dob", formatBackEndDate(event))
                          }
                          size={`270px`}
                          format={`dd/MM/yyyy`}
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                        />
                      </div>
                    </div>
                  )}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.dob && (
                    <div class="col-lg-4 mb-4">
                      <label>BVN:</label>
                      <div class="input-group">
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails
                              ? this.props.applicantDetails.bvn
                              : ""
                          }
                          placeholder=""
                          onChange={(event) =>
                            this.props.onChange("bvn", event.target.value)
                          }
                        />
                      </div>
                    </div>
                  )}
                {/* </div> */}
                {/* <div class="form-group row"> */}

                {this.props.applicantDetails &&
                  this.props.applicantDetails.dob && (
                    <div class="col-lg-4 mb-4">
                      <label>BVN Status:</label>
                      <div class="input-group">
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails
                              ? this.props.applicantDetails
                                  .bvnVerificationStatus
                              : ""
                          }
                          placeholder=""
                          onChange={(event) =>
                            this.props.onChange(
                              "bvnVerificationStatus",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  )}

                <div class="col-lg-4 mb-4">
                  <label>NIN:</label>
                  <div class="input-group">
                    <input
                      autoComplete="off"
                      disabled={this.props.disabled ? this.props.disabled : ""}
                      type="text"
                      class="form-control"
                      defaultValue={
                        this.props.applicantDetails
                          ? this.props.applicantDetails.nin
                          : ""
                      }
                      placeholder=""
                      onChange={(event) =>
                        this.props.onChange("nin", event.target.value)
                      }
                    />
                  </div>
                </div>

                <div class="col-lg-4 mb-4">
                  <label>NIN Status:</label>
                  <div class="input-group">
                    <input
                      autoComplete="off"
                      disabled={this.props.disabled ? this.props.disabled : ""}
                      type="text"
                      class="form-control"
                      defaultValue={
                        this.props.applicantDetails
                          ? this.props.applicantDetails.ninVerificationStatus
                          : ""
                      }
                      placeholder=""
                      onChange={(event) =>
                        this.props.onChange(
                          "ninVerificationStatus",
                          event.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row">
                {this.props.applicantDetails &&
                  this.props.applicantDetails.gender && (
                    <div class="col-lg-4 mb-4">
                      <label>Gender *:</label>
                      <div class="col-9">
                        <div class="kt-radio-inline">
                          <label class="kt-radio">
                            <input
                              autoComplete="off"
                              disabled={
                                this.props.disabled ? this.props.disabled : ""
                              }
                              type="radio"
                              name="gender"
                              value="MALE"
                              defaultChecked={
                                this.props.applicantDetails &&
                                this.props.applicantDetails.gender &&
                                this.props.applicantDetails.gender.toUpperCase() ===
                                  "MALE"
                              }
                              onChange={(event) =>
                                this.props.onChange(
                                  "gender",
                                  event.target.value
                                )
                              }
                            />{" "}
                            Male
                            <span></span>
                          </label>
                          <label class="kt-radio">
                            <input
                              autoComplete="off"
                              disabled={
                                this.props.disabled ? this.props.disabled : ""
                              }
                              type="radio"
                              name="gender"
                              value="FEMALE"
                              defaultChecked={
                                this.props.applicantDetails &&
                                this.props.applicantDetails.gender &&
                                this.props.applicantDetails.gender.toUpperCase() ===
                                  "FEMALE"
                              }
                              onChange={(event) =>
                                this.props.onChange(
                                  "gender",
                                  event.target.value
                                )
                              }
                            />{" "}
                            Female
                            <span></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
              </div>

              <div class="form-group row">
                {this.props.applicantDetails &&
                  this.props.applicantDetails.emailAddress && (
                    <div class="col-lg-4 mb-4">
                      <label>Email *:</label>
                      <div class="kt-input-icon">
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="email"
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails
                              ? this.props.applicantDetails.emailAddress
                              : ""
                          }
                          placeholder="Enter email address"
                          onChange={(event) => {
                            this.props.updateSetState(
                              "emailIsValid",
                              checkEmailIsValid(event.target.value)
                            );
                            this.props.onChange("email", event.target.value);
                          }}
                        />
                        {this.props.applicantDetailsFormData.email &&
                          !this.props.emailIsValid && (
                            <span class="kt-input-icon__icon kt-input-icon__icon--right">
                              <span>
                                <i
                                  class="la la-info-circle"
                                  style={{ color: "red" }}
                                ></i>
                              </span>
                            </span>
                          )}
                      </div>
                    </div>
                  )}
                {((this.props.applicantDetails &&
                  this.props.applicantDetails.phoneNumber) ||
                  this.props.showPhoneInput) && (
                  <div class="col-lg-4 mb-4">
                    <label>Phone *:</label>
                    <div class="kt-input-icon">
                      <PhoneNumberComponent
                        phoneIsValid={
                          this.props.phoneIsValid
                            ? this.props.phoneIsValid
                            : true
                        }
                        onChange={(status, phoneNumber) => {
                          this.props.onChange("phone", phoneNumber);
                          this.props.updateSetState(
                            "phoneIsValid",
                            status || isPhoneNumberValid(phoneNumber)
                          );
                        }}
                        defaultValue={
                          this.props.applicantDetails &&
                          this.props.applicantDetails.phoneNumber
                            ? this.props.applicantDetails.phoneNumber
                            : ""
                        }
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                      />
                      {this.props.applicantDetailsFormData.phone &&
                        !this.props.phoneIsValid && (
                          <span class="kt-input-icon__icon kt-input-icon__icon--right">
                            <span>
                              <i
                                class="la la-info-circle"
                                style={{ color: "red" }}
                              ></i>
                            </span>
                          </span>
                        )}
                    </div>
                    <span class="form-text text-muted">Please enter phone</span>
                  </div>
                )}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.nationality && (
                    <div class="col-lg-4 mb-4">
                      <label>Country *:</label>
                      {/* <Select
                    options={this.props.countries.map((detail) => {
                      return { label: detail.name, value: detail.id };
                    })}
                    onChange={(event) => this.props.updateCountry(event.value)}
                    minHeight={37}
                    disabled={this.props.disabled}
                    defaultValue={
                      this.props.applicantDetailsFormData
                        ? this.props.applicantDetailsFormData.country ||
                          this.props.applicantDetailsFormData.nationality
                        : this.props.applicantDetails.nationality
                    }
                  />
                  <span class="form-text text-muted">
                    Please enter your country
                  </span> */}
                      {this.props.disabled ? (
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails
                              ? this.props.getCountryName(
                                  this.props.applicantDetails.nationality
                                )
                              : this.props.applicantDetails.nationality
                          }
                          placeholder=""
                          onChange={(event) =>
                            this.props.onChange(
                              "nationality",
                              event.target.value
                            )
                          }
                        />
                      ) : (
                        <>
                          <Select
                            options={this.props.countries.map((detail) => {
                              return { label: detail.name, value: detail.id };
                            })}
                            onChange={(event) =>
                              this.props.updateCountry(event.value)
                            }
                            minHeight={37}
                            disabled={this.props.disabled}
                            defaultValue={
                              this.props.applicantDetailsFormData
                                ? this.props.applicantDetailsFormData.country ||
                                  this.props.applicantDetailsFormData
                                    .nationality
                                : this.props.applicantDetails.nationality
                            }
                          />
                          <span class="form-text text-muted">
                            Please enter your country
                          </span>
                        </>
                      )}
                    </div>
                  )}
                {/* </div> */}

                {/* <div class="form-group row"> */}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.state && (
                    <div class="col-lg-4 mb-4">
                      <label>State *:</label>
                      {this.props.disabled ? (
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails
                              ? this.props.getStateName(
                                  this.props.applicantDetails.state
                                )
                              : this.props.applicantDetails.state
                          }
                          placeholder=""
                          onChange={(event) =>
                            this.props.onChange("state", event.target.value)
                          }
                        />
                      ) : (
                        <>
                          <Select
                            options={this.props.states.map((detail) => {
                              return { label: detail.name, value: detail.id };
                            })}
                            onChange={(event) =>
                              this.props.updateState(
                                event.value,
                                "applicantState"
                              )
                            }
                            minHeight={37}
                            disabled={this.props.disabled}
                            defaultValue={
                              this.props.applicantDetailsFormData
                                ? this.props.applicantDetailsFormData.state
                                : this.props.applicantDetails.state
                            }
                          />
                          <span class="form-text text-muted">
                            Please enter your state
                          </span>
                        </>
                      )}
                    </div>
                  )}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.localGovernmentArea && (
                    <div class="col-lg-4 mb-4">
                      <label>LGA *:</label>
                      {this.props.disabled ? (
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails
                              ? this.props.getLgaName(
                                  this.props.applicantDetails
                                    .localGovernmentArea
                                )
                              : this.props.applicantDetails.localGovernmentArea
                          }
                          placeholder=""
                          onChange={(event) =>
                            this.props.onChange(
                              "localGovernmentArea",
                              event.target.value
                            )
                          }
                        />
                      ) : (
                        <>
                          <Select
                            options={this.props.lgas.map((detail) => {
                              return { label: detail.name, value: detail.id };
                            })}
                            onChange={(event) =>
                              this.props.onChange("lga", event.value)
                            }
                            minHeight={37}
                            disabled={this.props.disabled}
                            defaultValue={
                              this.props.applicantDetailsFormData
                                ? this.props.applicantDetailsFormData.lga
                                : this.props.applicantDetails
                                    .localGovernmentArea
                            }
                          />
                          <span class="form-text text-muted">
                            Please enter your LGA
                          </span>
                        </>
                      )}
                    </div>
                  )}
                {this.props.applicantDetails &&
                  this.props.applicantDetails.closestLandmark && (
                    <div class="col-lg-4 mb-4">
                      <label>Landmark *:</label>
                      <input
                        autoComplete="off"
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                        type="text"
                        class="form-control"
                        placeholder="Closest landmark"
                        defaultValue={
                          this.props.applicantDetails
                            ? this.props.applicantDetails.closestLandmark
                            : ""
                        }
                        onChange={(event) =>
                          this.props.onChange(
                            "closestLandmark",
                            event.target.value
                          )
                        }
                      />
                      <span class="form-text text-muted">
                        Please enter the closest landmark
                      </span>
                    </div>
                  )}
              </div>
              <div class="form-group row">
                {this.props.applicantDetails &&
                  this.props.applicantDetails.address && (
                    <div class="col-lg-12">
                      <label>Address *:</label>
                      <textarea
                        autoComplete="off"
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                        rows={5}
                        style={{ resize: "none" }}
                        defaultValue={
                          this.props.applicantDetails
                            ? this.props.applicantDetails.address
                            : ""
                        }
                        class="form-control"
                        placeholder="Address"
                        onChange={(event) =>
                          this.props.onChange("address", event.target.value)
                        }
                      />
                      <span class="form-text text-muted">
                        What's your address
                      </span>
                    </div>
                  )}
              </div>

              <div class="form-group row">
                {this.props.applicantDetails &&
                  this.props.applicantDetails.howYouHeardAboutUs && (
                    <div class="col-lg-4 mb-4">
                      <label>Channel *:</label>
                      <input
                        autoComplete="off"
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                        type="text"
                        class="form-control"
                        defaultValue={
                          this.props.applicantDetails
                            ? this.props.applicantDetails.howYouHeardAboutUs
                            : ""
                        }
                        placeholder="Radio"
                        onChange={(event) =>
                          this.props.onChange(
                            "howYouHeardAboutUs",
                            event.target.value
                          )
                        }
                      />
                      <span class="form-text text-muted">
                        How did you hear about us?
                      </span>
                    </div>
                  )}
                {this.props.showAgentType && (
                  <div class="col-lg-4 mb-4">
                    <label>Category *:</label>
                    <input
                      autoComplete="off"
                      disabled
                      type="text"
                      class="form-control"
                      defaultValue={
                        this.props.applicantDetailsFormData
                          ? getApplicationType(
                              this.props.applicantDetailsFormData.agentTypeId
                            )
                          : ""
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
