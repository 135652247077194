import React from 'react'
import Select from '../select'

export default class AgentClassAssignment extends React.Component {
    render() {
        return <React.Fragment>
            {this.props.toShowValidationButton &&
                <div class="alert alert-danger" role="alert">
                    <div class="alert-text">Assign agent class and agent type</div>
                </div>
            }
            <div hidden={!this.props.toShowValidationButton && !this.props.toShowApprovalButton} class="kt-portlet" ref={this.props.agentAssignmentRef}>
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label" data-toggle="collapseOne5" role="button" data-target="#collapseOne5" aria-expanded="true" aria-controls="collapseOne5">
                        <h3 class="kt-portlet__head-title">
                            Agent Class Assignment
                        </h3>
                    </div>
                </div>

                <form class="kt-form kt-form--label-right" id="collapseOne5" class="collapse show" aria-labelledby="headingOne5">
                    <div class="kt-portlet__body">
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label class="">Agent Class <span style={{color: '#EE312A'}}>*</span>:</label>
                                <div class="form-group row">
                                    <div class="col-lg-11">
                                        <Select
                                            options={this.props.agentClasses.map(detail => { return ( {label: detail.name, value: detail.id} ) })} 
                                            onChange={(event) => this.props.onChange('agentClass', event.value)}
                                            minHeight={27}
                                            defaultValue={this.props.agentClassId}
                                            isLoading={this.props.isAgentClassLoading}
                                        />
                                    </div>
                                    <div class="col-lg-1">
                                        <div style={{marginTop: '7px', cursor: 'pointer'}}>
                                            <span class={`la ${this.props.isAgentClassLoading ? 'la-spin' : ''} la-refresh`} onClick={this.props.getAgentClasses}></span>
                                        </div>
                                    </div> 
                                </div>
                            </div>

                            <div class="col-lg-4" hidden>
                                <label class="">Agent Type <span style={{color: '#EE312A'}}>*</span>:</label>
                                <div class="form-group row">
                                    <div class="col-lg-11">
                                        <Select
                                            options={this.props.agentTypes.map(detail => { return ( {label: detail.name, value: detail.id} ) })} 
                                            onChange={(event) => this.props.onChange('agentType', event.value)}
                                            minHeight={27}
                                            defaultValue={this.props.agentTypeId}
                                            //isLoading={isAgentTypeLoading}
                                        />
                                    </div>
                                    <div class="col-lg-1">
                                        {/* <div style={{marginTop: '10px', cursor: 'pointer'}}>
                                            <span class={`la ${isAgentTypeLoading ? 'la-spin la-10x' : ''} la-refresh`} onClick={getAgentTypes}></span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4" hidden={this.props.agentTypeId == 4 ? '' : 'hidden'}>
                                <label class="">Aggregator <span style={{color: '#EE312A'}}>*</span>:</label>
                                <div class="form-group row">
                                    <div class="col-lg-11">
                                        <Select
                                            options={this.props.superAgents.map(detail => { return ( {label: detail.businessName, value: detail.referralCode} ) })} 
                                            onChange={(event) => this.props.onChange('superAgent', event.value)}
                                            minHeight={27}
                                            defaultValue={this.props.referralCode}
                                            isLoading={this.props.isAggregatorListLoading}
                                        />
                                    </div>
                                    <div class="col-lg-1">
                                        <div style={{marginTop: '5px', cursor: 'pointer'}}>
                                            <span class={`la ${this.props.isAggregatorListLoading ? 'la-spin' : ''} la-refresh`} onClick={this.props.getAggregatorList}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}