import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ApplicantDetailsForm from "../../../../../../../components/application-form/applicant-details";
import BusinessDetailsForm from "../../../../../../../components/application-form/business-details";
import KycDocuments from "../../../../../../../components/application-form/kyc-documents";
import NextOfKinDetailsForm from "../../../../../../../components/application-form/next-of-kin-details";
import { OtherDetails } from "../../../../../../../components/application-form/other-details";
import WalletBalance from "../../../../../../../components/application-form/wallet-balance";
import ContentHead from "../../../../../../../components/content-head";
import { GridItem } from "../../../../../../../components/layouts/grid-item";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import {
  CAN_VIEW_AGENT,
  CAN_VIEW_TRANSACTION,
} from "../../../../../../../constants/permissions.js";
import Banks from "../../../../../../../fixtures/banks";
import BusinessTypes from "../../../../../../../fixtures/business_types.json";
import Countries from "../../../../../../../fixtures/countries.json";
import IDTYPES from "../../../../../../../fixtures/id-types.json";
import LGAs from "../../../../../../../fixtures/lgas.json";
import PersonalDocuments from "../../../../../../../fixtures/personal_documents.json";
import Relationships from "../../../../../../../fixtures/relationships.json";
import States from "../../../../../../../fixtures/states.json";
import UtilityBills from "../../../../../../../fixtures/utility_bills.json";
import {
  platformManagement,
  transactionService,
} from "../../../../../../../mixins/api";
import { saveOtherInformation } from "../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  AgentDetailsSerializer,
  DomainDetailsSerializer,
} from "../../../../../../../serializers";
import { formatDateTime2 } from "../../../../../../../utils/formatter";
import {
  getLgasByStateId,
  getStatesByCountryId,
} from "../../../../../../../utils/helper";

export default class ViewAgentScene extends BaseScene {
  notificationSectionRef = React.createRef(null);
  canSubmit = false;

  constructor(props) {
    super(props);

    this.requiredApplicantDetailsFields = ["firstName", "lastName", "phone"];

    this.allApplicantDetailsFields = [
      ...this.requiredApplicantDetailsFields,
      "gender",
      "mothersMaidenName",
      "closestLandmark",
      "placeOfBirth",
      "identificationNumber",
      "state",
      "lga",
    ];

    this.allNextOfKinFields = ["firstName", "surname", "phoneNumber", "gender"];

    this.allBusinessDetailsFields = [
      "businessName",
      "businessType",
      "state",
      "localGovernmentArea",
      "address",
      "bankName",
      "accountNumber",
    ];

    this.allValidationFields = [
      "phoneIsValid",
      "companyRegistrationNumberIsValid",
    ];

    // this.allValidationFields = [
    //   "bvnIsValid", "emailIsValid", "passwordIsValid", "phoneIsValid",
    //   "nextOfKinPhoneIsValid", "accountNumberIsValid"
    // ]

    this.state = {
      identificationTypes: IDTYPES,
      uploadedDocuments: [],
      agentCode: "",
      agentId: "",
      message: "",
      loading: false,
      disabled: true,
      aggregators: [],
      agentClasses: [],
      domainDetails: {
        applicantDetails: {
          nextOfKin: {},
        },
        businessDetails: {},
      },
      applicantDetailsFormData: {
        nextOfKin: {},
      },
      businessDetailsFormData: {},
      isLoading: true,
      businessTypes: BusinessTypes,
      countries: Countries,
      states: States,
      lgas: LGAs,
      businessLgas: LGAs,
      applicantLgas: LGAs,
      ledgerBalance: 0,
      transactionBalance: 0,
      commissionBalance: 0,
      reloadWalletBalance: false,
      transactionLedgerBalance: 0,
      transactionAvailableBalance: 0,
      commissionLedgerBalance: 0,
      commissionAvailableBalance: 0,
      canUpdate: false,
      canSave: false,
      documentUploadProgress: [],
      documentErrors: [],
      kycDocuments: [],
      phoneIsValid: true,
      companyRegistrationNumberIsValid: true,
      accountNumberIsValid: true,
      bvnIsValid: true,
      nextOfKinPhoneIsValid: true,
      emailIsValid: true,
      businessEmailIsValid: true,
      businessPhoneIsValid: true,
      updateNextOfKinAddress: false,
      shoouldUpdateBusinessAddress: false,
      showPhoneInput: false,
      uploadedOtherDocuments: [],
      showCompleteConfigurationButton: false,
      exemptAgent: false,
    };

    this.loadApplication = this.loadApplication.bind(this);
    this.updateApplicantDetailsForm = this.updateApplicantDetailsForm.bind(
      this
    );
    this.updateBusinessDetailsForm = this.updateBusinessDetailsForm.bind(this);
    this.updateNextOfKinDetailsForm = this.updateNextOfKinDetailsForm.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
    this.changeExemption = this.changeExemption.bind(this);
    this.updateSetState = this.updateSetState.bind(this);
    this.getAllBalances = this.getAllBalances.bind(this);
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    this.updateState = this.updateState.bind(this);
    this.updateCountry = this.updateCountry.bind(this);
    this.readNationalIdUrl = this.readNationalIdUrl.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
    this.updateNextOfKinAddress = this.updateNextOfKinAddress.bind(this);
    this.updateBusinessAddress = this.updateBusinessAddress.bind(this);
    this.redirectToWalletJournal = this.redirectToWalletJournal.bind(this);
    this.completeConfiguration = this.completeConfiguration.bind(this);
    this.getIdentificationTypeName = this.getIdentificationTypeName.bind(this);
    this.getCountryName = this.getCountryName.bind(this);
    this.getStateName = this.getStateName.bind(this);
    this.getLgaName = this.getLgaName.bind(this);
    this.getBusinessTypeName = this.getBusinessTypeName.bind(this);
    this.getAgentClasssName = this.getAgentClassName.bind(this);
    this.getAggregatorName = this.getAggregatorName.bind(this);
  }

  componentDidMount() {
    this.agentId = this.props.match.params.id;
    this.loadApplication();
    //this.getUploadedDocuments()
    this.getAggregatorList();
    this.getAgentClasses();
    //this.getCountries()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }

    const emptyRequiredApplicantDetailsFields = this.requiredApplicantDetailsFields.filter(
      (value) => {
        return Boolean(this.state.applicantDetailsFormData[value]) === false;
      }
    );
    const emptyApplicantDetailsFields = this.allApplicantDetailsFields.filter(
      (value) => {
        return Boolean(this.state.applicantDetailsFormData[value]) === false;
      }
    );

    const emptyNextOfKinFields = this.allNextOfKinFields.filter((value) => {
      return (
        Boolean(this.state.applicantDetailsFormData.nextOfKin[value]) === false
      );
    });

    const emptyBusinessDetailsFields = this.allBusinessDetailsFields.filter(
      (value) => {
        return Boolean(this.state.businessDetailsFormData[value]) === false;
      }
    );

    const allFieldsEmpty =
      emptyApplicantDetailsFields.length +
      emptyNextOfKinFields.length +
      emptyBusinessDetailsFields.length;
    const newState = {
      canSave: this.canSave,
      canSubmit: this.canSubmit,
    };

    if (emptyRequiredApplicantDetailsFields.length === 0) {
      newState.canSave = true;
    } else if (emptyRequiredApplicantDetailsFields.length !== 0) {
      newState.canSave = false;
    }

    if (allFieldsEmpty === 0) {
      newState.canSubmit = true;
    } else {
      newState.canSubmit = false;
    }

    //&& this.state.documentErrors.length === 0 && !this.canSubmit

    // if (allFieldsEmpty ===  0 && Object.keys(this.state.kycDocuments).length >= 4 && this.state.documentErrors.length === 0 && !this.canSubmit) {
    //   newState.canSubmit = true
    // }
    // if (Object.keys(this.state.kycDocuments).length >= 4) {
    //   newState.canSubmit = true
    // }
    // else if (allFieldsEmpty !==  0) {
    //   newState.canSubmit = false
    // }

    this.canSave = newState.canSave;
    this.canSubmit = newState.canSubmit;
  }

  async getAggregatorList() {
    const responseObj = await platformManagement.getSuperAgentList();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        aggregators: response,
      });
    } else {
      this.setState({
        aggregators: [],
      });
    }
  }

  async getAgentClasses() {
    const responseObj = await platformManagement.getAgentClasses();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        agentClasses: this.getActiveAgentClasses(response),
      });
    } else {
      this.setState({
        agentClasses: [],
      });
    }
  }

  async getCountries() {
    const responseObj = await platformManagement.getCountries();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        countries: response,
      });
    } else {
      this.setState({
        countries: Countries,
      });
    }
  }

  async getAgentWalletBalance(phoneNumber) {
    this.setState({ reloadWalletBalance: true });
    const responseObj = await transactionService.getWalletBalanceByPhoneNumber(
      phoneNumber
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        ledgerBalance: parseFloat(response.ledgerBalance),
        transactionBalance: parseFloat(response.transactionWalletBalance),
        commissionBalance: parseFloat(response.commissionWalletBalance),
        lastSuccessfulWalletBalanceTime: new Date(),
        reloadWalletBalance: false,
      });
    } else {
      this.setState({
        ledgerBalance: this.state.ledgerBalance,
        transactionBalance: this.state.transactionBalance,
        commissionBalance: this.state.commissionBalance,
        //lastSuccessfulWalletBalanceTime: new Date(),
        reloadWalletBalance: false,
      });
    }
  }

  async getAgentWalletDetails(type) {
    if (type === "TRAN") {
      this.setState({ reloadTransactionWalletDetails: true });
    } else if (type === "COM") {
      this.setState({ reloadCommissionWalletDetails: true });
    }
    const responseObj = await transactionService.getWalletDetails(
      `${type}_${this.agentId}`
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      if (type === "TRAN") {
        this.setState({
          transactionLedgerBalance: parseFloat(response.ledgerBalance),
          transactionAvailableBalance: parseFloat(response.availableBalance),
          transactionPAN: response.pan,
          lastSuccessfulTransactionWalletTime: new Date(),
          lastSuccessfulWalletDetailsTime: new Date(),
          reloadTransactionWalletDetails: false,
        });
      } else if (type === "COM") {
        this.setState({
          commissionLedgerBalance: parseFloat(response.ledgerBalance),
          commissionAvailableBalance: parseFloat(response.availableBalance),
          commissionPAN: response.pan,
          lastSuccessfulCommissionWalletTime: new Date(),
          lastSuccessfulWalletDetailsTime: new Date(),
          reloadCommissionWalletDetails: false,
        });
      }
    } else {
      if (type === "TRAN") {
        this.setState({
          transactionLedgerBalance: this.state.transactionLedgerBalance,
          transactionAvailableBalance: this.state.transactionAvailableBalance,
          transactionPAN: this.state.transactionPAN,
          //lastSuccessfulTransactionWalletTime: new Date(),
          reloadTransactionWalletDetails: false,
        });
      } else if (type === "COM") {
        this.setState({
          commissionLedgerBalance: this.state.commissionLedgerBalance,
          commissionAvailableBalance: this.state.commissionAvailableBalance,
          commissionPAN: this.state.commissionPAN,
          //lastSuccessfulCommissionWalletTime: new Date(),
          reloadCommissionWalletDetails: false,
        });
      }
    }
  }

  getActiveAgentClasses(response) {
    return response.filter((details) => details.disabled === 0);
  }

  // async getUploadedDocuments () {
  //   const responseObj = await platformManagement.getUploadedDocuments(this.agentId)
  //   const { status, response } = responseObj
  //   if (status === SUCCESS_STATUS) {
  //     this.setState({
  //       uploadedDocuments: response.data
  //     })
  //   }
  //   return
  // }

  handleChange = (e) => {
    this.setState({ exemptAgent: e.target.checked });
    this.changeExemption(e.target.checked);
  };

  async loadApplication() {
    const { status, response } = await platformManagement.getAgentByAgentCode(
      this.agentId
    );

    if (status === SUCCESS_STATUS) {
      this.getAgentWalletBalance(response.agentMobileNo);
      this.getAgentWalletDetails("TRAN");
      this.getAgentWalletDetails("COM");
      this.setState({
        agentCode: response.agentCode,
        agentId: response.id,
        exemptAgent: response.kycCheckList.skipCacTinCheck,
        locationId: response.businessLocation
          ? response.businessLocation[0].id
          : null,
        canUpdate: true,
        isLoading: false,
        uploadedDocuments: response.documents ? response.documents : [],
        domainDetails: {
          applicantDetails: {
            ...this.state.applicantDetails,
            ...DomainDetailsSerializer.serializeApplicantDetailsFromDomainDetails(
              response
            ),
          },
          businessDetails: {
            ...this.state.businessDetails,
            ...DomainDetailsSerializer.serializeBusinessDetailsFromDomainDetails(
              response
            ),
          },
        },
        agentFormData: response,
        applicantDetailsFormData: {
          ...this.state.applicantDetails,
          ...DomainDetailsSerializer.serializeApplicantDetailsFromDomainDetails(
            response
          ),
          //...ApplicantDetailsSerializer.reverseSerializeFromApplication(response.businessContact || {}),
          howYouHeardAboutUs: response.howYouHeardAboutUs
            ? response.howYouHeardAboutUs
            : "",
        },
        businessDetailsFormData: {
          ...this.state.businessDetails,
          ...DomainDetailsSerializer.serializeBusinessDetailsFromDomainDetails(
            response
          ),
        },
        showPhoneInput: true,
        showCompleteConfigurationButton:
          response.walletStatus === "WALLET_CREATED" ? false : true,
      });
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
        showPhoneInput: true,
      });
    }
  }

  getAllBalances() {
    //this.setState({reloadWalletBalance: true})
    this.getAgentWalletBalance(
      this.state.domainDetails.applicantDetails.phoneNumber
    );
    this.getAgentWalletDetails("TRAN");
    this.getAgentWalletDetails("COM");
  }

  updateApplicantDetailsForm(type, value) {
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        [type]: value,
      },
    });
    setTimeout(() => {
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          [type]: value,
        },
      });
    }, 1000);
  }

  updateBusinessDetailsForm(type, value) {
    this.setState({
      businessDetailsFormData: {
        ...this.state.businessDetailsFormData,
        [type]: value,
      },
    });
    setTimeout(() => {
      this.setState({
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          [type]: value,
        },
      });
    }, 1000);
  }

  updateNextOfKinDetailsForm(type, value) {
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        nextOfKin: {
          ...this.state.applicantDetailsFormData.nextOfKin,
          [type]: value,
        },
      },
    });
    setTimeout(() => {
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          nextOfKin: {
            ...this.state.applicantDetailsFormData.nextOfKin,
            [type]: value,
          },
        },
      });
    }, 1000);
  }

  updateSetState(type, value) {
    this.setState({ [type]: value });
  }

  async updateCountry(countryId) {
    let applicantDetails = this.state.domainDetails.applicantDetails;
    applicantDetails.state = null;
    applicantDetails.localGovernmentArea = "null";
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        country: countryId,
        state: null,
        lga: null,
        localGovernmentArea: null,
      },
    });
    this.setState({
      states: getStatesByCountryId(countryId),
      canUploadDocument: false,
    });
  }

  async updateState(stateId, type) {
    this.setState({ lgas: [] });
    if (type === "applicantState") {
      let applicantDetails = this.state.domainDetails.applicantDetails;
      applicantDetails.localGovernmentArea = "null";
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          state: stateId,
          lga: null,
          localGovernmentArea: null,
        },
        applicantLgas: getLgasByStateId(stateId),
      });
    } else {
      let businessDetails = this.state.domainDetails.businessDetails;
      businessDetails.localGovernmentArea = "null";
      this.setState({
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          state: stateId,
          localGovernmentArea: null,
        },
        businessLgas: getLgasByStateId(stateId),
      });
    }
  }

  onUpdateButtonClick() {
    this.setState({
      canUpdate: false,
      canSave: true,
      disabled: false,
    });
  }

  onCancelButtonClick() {
    this.setState({
      canUpdate: true,
      canSave: false,
      disabled: true,
    });
  }

  async onSubmitButtonClick() {
    this.setState({ isLoading: true });

    const agentFormData = this.agentFormSerializer();
    agentFormData.documents = this.state.uploadedDocuments;

    const responseObj = await platformManagement.updateAgentDetails(
      this.agentId,
      agentFormData
    );
    const { status, response } = responseObj;
    window.scrollTo(0, this.notificationSectionRef.offsetTop);
    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your changes to this agent has been sent successfully for approval.",
          canUpdate: true,
          canSave: false,
          disabled: true,
        });
        return;
      } else {
        this.setState({
          isLoading: false,
          successMessage: "Agent details updated successfully",
          canUpdate: true,
          canSave: false,
          disabled: true,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
    this.loadApplication();
  }

  async changeExemption(params) {
    try {
      const responseObj = await platformManagement.exemptUsers(
        this.state.agentId,
        params
      );

      if (
        responseObj.response.description === "Successful" &&
        responseObj.status === "SUCCESS"
      ) {
        this.setState({
          successMessage: "Updated Successfully",
        });
        return;
      } else {
        this.setState({
          errorMessage: "Something went wrong, please try again",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  agentFormSerializer() {
    let serializedFormData = {};
    serializedFormData = {
      ...AgentDetailsSerializer.serializeApplicantDetails(
        this.state.applicantDetailsFormData,
        this.state.businessDetailsFormData
      ),
      ...AgentDetailsSerializer.serializeBusinessDetails(
        this.state.applicantDetailsFormData,
        this.state.businessDetailsFormData
      ),
    };
    return serializedFormData;
  }

  readNationalIdUrl(documentId, documentType, event, documentName) {
    const document = {
      name: documentType,
      file: event.target.files[0],
      filename: event.target.files[0].name,
      url: URL.createObjectURL(event.target.files[0]),
    };
    this.setState({
      kycDocuments: {
        ...this.state.kycDocuments,
        [documentType]: document,
      },
    });
    this.uploadDocument(documentId, document.file, documentType, documentName);
  }

  async uploadDocument(documentId, file, type, documentType) {
    if (typeof documentId === "number") {
      const documentType = `${type}`;
      this.setState({
        documentUploadProgress: {
          ...this.state.documentUploadProgress,
          [documentType]: true,
        },
      });
      const responseObj = await platformManagement.documentUpload(
        documentId,
        documentType,
        file
      );

      const { status, response } = responseObj;
      if (status === SUCCESS_STATUS) {
        this.addNewUploadedDocument(documentId, response.data);
        this.state.documentErrors.pop(documentType);
      } else {
        this.state.documentErrors.indexOf(documentType) === -1 &&
          this.setState({
            documentErrors: [...this.state.documentErrors, documentType],
          });
      }
      setTimeout(() => {
        this.setState({
          documentUploadProgress: {
            ...this.state.documentUploadProgress,
            [documentType]: false,
          },
        });
      }, 3000);
    } else {
      this.setState({
        loading: true,
        message: "",
      });
      const responseObj = await platformManagement.documentUploadNew(
        this.state.agentCode,
        this.state.locationId,
        documentType,
        file,
        file.name
      );
      const { status, response } = responseObj;
      if (status === SUCCESS_STATUS) {
        this.setState({
          loading: false,
          canUpdate: true,
          canSave: false,
          disabled: true,
        });

        this.loadApplication();
      } else {
        this.setState({
          loading: false,
          message: response.message,
        });
      }
    }
  }

  async completeConfiguration() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.completeConfiguration(
      this.agentId
    );
    const { status, response } = responseObj;
    window.scrollTo(0, this.notificationSectionRef.offsetTop);
    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage: "Configuration is in progress",
          canUpdate: true,
          canSave: false,
          disabled: true,
        });
        return;
      } else {
        this.setState({
          isLoading: false,
          successMessage: "Agent configuration completed successfully.",
          canUpdate: true,
          canSave: false,
          disabled: true,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
    this.loadApplication();
  }

  addNewUploadedDocument(documentId, uploadedDocumentData) {
    const uploadedDocuments = this.state.uploadedDocuments;
    uploadedDocuments.filter((value) => {
      if (value.documentId == documentId) {
        value.docuemntExtention = uploadedDocumentData.documentExtention;
        value.documentLink = uploadedDocumentData.documentLink;
        value.documentName = uploadedDocumentData.documentName;
        value.documentType = uploadedDocumentData.documentType;
      }
    });
    this.setState({ uploadedDocuments });
  }

  updateNextOfKinAddress(shouldUpdate) {
    if (shouldUpdate) {
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          nextOfKin: {
            ...this.state.applicantDetailsFormData.nextOfKin,
            address: this.state.applicantDetailsFormData.address,
          },
        },
      });
    } else {
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          nextOfKin: {
            ...this.state.applicantDetailsFormData.nextOfKin,
            address: "",
          },
        },
      });
    }
  }

  updateBusinessAddress(shouldUpdate) {
    if (shouldUpdate) {
      this.setState({
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          address: this.state.applicantDetailsFormData.address,
          state: this.state.applicantDetailsFormData.state,
          localGovernmentArea: this.state.applicantDetailsFormData.lga,
        },
      });
    } else {
      this.setState({
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          address: null,
          state: null,
          localGovernmentArea: null,
        },
      });
    }
  }

  isFormValid() {
    return (
      !this.state.phoneIsValid ||
      !this.state.businessPhoneIsValid ||
      !this.state.businessEmailIsValid ||
      !this.state.emailIsValid ||
      !this.state.nextOfKinPhoneIsValid ||
      !this.state.companyRegistrationNumberIsValid ||
      !this.state.accountNumberIsValid ||
      !this.state.bvnIsValid
    );
  }

  redirectToWalletJournal(agentCode) {
    const agent = {
      agentCode: this.agentId,
      businessName: this.state.domainDetails.businessDetails.businessName,
      agentMobileNo: this.state.domainDetails.businessDetails.phoneNumber,
    };
    saveOtherInformation("agentDetails", JSON.stringify(agent));
    this.setState({
      redirectToWalletJournal: true,
    });
  }

  getIdentificationTypeName(id) {
    const details = this.state.identificationTypes.filter(
      (value) => value.id == id
    );
    return details[0] ? details[0].name : id;
  }

  getCountryName(id) {
    const details = this.state.countries.filter(
      (value) => value.id == parseInt(id)
    );
    return details[0] ? details[0].name : "";
  }

  getStateName(id) {
    const details = States.filter((value) => value.id == parseInt(id));
    return details[0] ? details[0].name : id;
  }

  getLgaName(id) {
    const details = LGAs.filter((value) => value.id == parseInt(id));
    return details[0] ? details[0].name : id;
  }

  getBusinessTypeName(id) {
    const details = this.state.businessTypes.filter((value) => value.id == id);
    return details[0] ? details[0].business_type : id;
  }

  getAgentClassName(id) {
    const details = this.state.agentClasses.filter((value) => value.id == id);
    return details[0] ? details[0].name : "";
  }

  getAggregatorName(id) {
    const details = this.state.aggregators.filter(
      (value) => value.referralCode == id
    );
    return details[0] ? details[0].businessName : id;
  }

  render() {
    if (this.state.redirectToWalletJournal === true) {
      //window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`;
      return <Redirect to={`/home/wallet-journal/${this.agentId}`} />;
    }

    const ctaWidgets = (
      <React.Fragment>
        {this.state.showCompleteConfigurationButton && (
          <button
            class="btn"
            style={{ backgroundColor: "#00B8DE", color: "white" }}
            onClick={this.completeConfiguration}
          >
            Complete Configuration
          </button>
        )}
        {this.state.canUpdate && (
          <button
            class="btn"
            style={{ backgroundColor: "#00425f", color: "white" }}
            onClick={this.onUpdateButtonClick}
          >
            Edit
          </button>
        )}
        {this.state.canSave && (
          <button
            class="btn btn-secondary btn-bold btn-sm btn-icon-h"
            id="kt_subheader_group_actions_fetch"
            onClick={this.onCancelButtonClick}
          >
            Cancel
          </button>
        )}
        {this.state.canSave && (
          <button
            disabled={this.isFormValid()}
            class="btn"
            style={{ backgroundColor: "#00425f", color: "white" }}
            onClick={this.onSubmitButtonClick}
          >
            Save Changes
          </button>
        )}
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          ref={this.notificationSectionRef}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"View Agent"}
          withoutSearch
          subtitle="View an Agent Details"
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_AGENT]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridItem>
            <ApplicantDetailsForm
              identificationTypes={this.state.identificationTypes}
              applicantDetails={this.state.domainDetails.applicantDetails}
              onChange={this.updateApplicantDetailsForm}
              updateSetState={this.updateSetState}
              applicantDetailsFormData={this.state.applicantDetailsFormData}
              countries={this.state.countries}
              states={this.state.states}
              lgas={this.state.applicantLgas}
              updateCountry={this.updateCountry}
              updateState={this.updateState}
              disabled={this.state.disabled}
              title={"Agent"}
              phoneIsValid={this.state.phoneIsValid}
              emailIsValid={this.state.emailIsValid}
              showPhoneInput={this.state.showPhoneInput}
              getStateName={this.getStateName}
              getLgaName={this.getLgaName}
              getCountryName={this.getCountryName}
              getIdentificationTypeName={this.getIdentificationTypeName}
            />
            <BusinessDetailsForm
              disabled={this.state.disabled}
              businessTypes={this.state.businessTypes}
              businessDetailsFormData={this.state.businessDetailsFormData}
              applicantDetailsFormData={this.state.applicantDetailsFormData}
              states={this.state.states}
              lgas={this.state.businessLgas}
              banks={Banks}
              applicantDetails={this.state.domainDetails.applicantDetails}
              onChange2={this.updateApplicantDetailsForm}
              onChange={this.updateBusinessDetailsForm}
              businessDetails={this.state.domainDetails.businessDetails}
              updateSetState={this.updateSetState}
              updateCountry={this.updateCountry}
              updateState={this.updateState}
              showAgentClass={true}
              agentClasses={this.state.agentClasses}
              aggregators={this.state.aggregators}
              businessPhoneIsValid={this.state.businessPhoneIsValid}
              companyRegistrationNumberIsValid={
                this.state.companyRegistrationNumberIsValid
              }
              accountNumberIsValid={this.state.accountNumberIsValid}
              bvnIsValid={this.state.bvnIsValid}
              businessEmailIsValid={this.state.businessEmailIsValid}
              shouldUpdateBusinessAddress={
                this.state.shouldUpdateBusinessAddress
              }
              updateBusinessAddress={this.updateBusinessAddress}
              showPhoneInput={this.state.showPhoneInput}
              disabledAgentClass={true}
              getStateName={this.getStateName}
              getLgaName={this.getLgaName}
              changeExemption={this.changeExemption}
              exemptAgent={this.state.exemptAgent}
              handleChange={this.handleChange}
            />
            <WalletBalance
              ledgerBalance={this.state.ledgerBalance}
              transactionBalance={this.state.transactionBalance}
              commissionBalance={this.state.commissionBalance}
              lastBalanceTime={
                this.state.lastSuccessfulWalletBalanceTime
                  ? formatDateTime2(this.state.lastSuccessfulWalletBalanceTime)
                  : ""
              }
              reloadWalletBalance={this.state.reloadWalletBalance}
              getAllBalances={this.getAllBalances}
              canViewTransaction={CAN_VIEW_TRANSACTION}
              redirectToWalletJournal={this.redirectToWalletJournal}
              showWalletJournalLink={true}
              transactionLedgerBalance={this.state.transactionLedgerBalance}
              transactionAvailableBalance={
                this.state.transactionAvailableBalance
              }
              transactionPAN={this.state.transactionPAN}
              commissionLedgerBalance={this.state.commissionLedgerBalance}
              commissionAvailableBalance={this.state.commissionAvailableBalance}
              commissionPAN={this.state.commissionPAN}
              lastSuccessfulWalletDetailsTime={
                this.state.lastSuccessfulWalletDetailsTime
                  ? formatDateTime2(this.state.lastSuccessfulWalletDetailsTime)
                  : ""
              }
              lastSuccessfulCommissionWalletTime={
                this.state.lastSuccessfulCommissionWalletTime
                  ? formatDateTime2(
                      this.state.lastSuccessfulCommissionWalletTime
                    )
                  : ""
              }
              lastSuccessfulTransactionWalletTime={
                this.state.lastSuccessfulTransactionWalletTime
                  ? formatDateTime2(
                      this.state.lastSuccessfulTransactionWalletTime
                    )
                  : ""
              }
            />
            <NextOfKinDetailsForm
              disabled={this.state.disabled}
              nextOfKinDetails={
                this.state.domainDetails.applicantDetails.nextOfKin
              }
              shouldUpdateNextOfKinAddress={
                this.state.shouldUpdateNextOfKinAddress
              }
              updateNextOfKinAddress={this.updateNextOfKinAddress}
              updateSetState={this.updateSetState}
              onChange={this.updateNextOfKinDetailsForm}
              applicantDetailsFormData={this.state.applicantDetailsFormData}
              relationships={Relationships}
              nextOfKinPhoneIsValid={this.state.nextOfKinPhoneIsValid}
              showPhoneInput={this.state.showPhoneInput}
            />
            <KycDocuments
              showUploadForm={
                this.state.uploadedDocuments.length ? false : true
              }
              message={this.state.message}
              showUploadedDocuments={true}
              isLoading={this.state.isLoading}
              loading={this.state.loading}
              kycDocuments={this.state.kycDocuments}
              documentErrors={this.state.documentErrors}
              utilityBills={UtilityBills}
              personalDocuments={PersonalDocuments}
              uploadedDocuments={this.state.uploadedDocuments}
              nextOfKinPhoneIsValid={true}
              readNationalIdUrl={this.readNationalIdUrl}
              documentUploadProgress={this.state.documentUploadProgress}
              uploadDocument={this.uploadDocument}
              disabled={this.state.disabled}
              uploadedOtherDocuments={this.state.uploadedOtherDocuments}
            />
            <OtherDetails
              disabled={true}
              bvnVerificationStatus={
                this.state.agentFormData !== undefined
                  ? this.state.agentFormData.bvnVerificationStatus
                  : undefined
              }
              applicantDetails={this.state.domainDetails.applicantDetails}
            />
          </GridItem>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
